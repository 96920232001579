import { BasicBreadcrumbs, useIntl } from "@datwyler/mfe-shared-components";

const Breadcrumbs = () => {
  const intl = useIntl();

  return (
    <BasicBreadcrumbs
      items={[
        {
          title: intl.formatMessage({ id: "menu_home" }),
          href: "/home",
        },
        {
          title: intl.formatMessage({ id: "menu_user" }),
        },
      ]}
    />
  );
};

export default Breadcrumbs;
